import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Events = () => (
  <Layout>
    <SEO title="Events" />
    <section>
      <div className="container py-8">
        <div className="row">
          <div className="col col-md-4">
            <div className="card" style={{ width: "18rem" }}>
              <img
                    alt="Image placeholder"
                    src={require("../images/downloads/RY6B0950.jpg")}
                    className="card-img-top"
                  />
              <div className="card-body">
                <Link to="/events/study-in-rwanda-ur-orientation" className="text-link">
                  Study in Rwanda UR orientation
                </Link>
                <br />
                <small>Venue: Camp Kigali</small>
              </div>
            </div>
          </div>
          <div className="col col-md-4">
            <div className="card" style={{ width: "17rem" }}>
              <img
                    alt="Image placeholder"
                    src="https://ur.ac.rw/IMG/jpg/untitled-111.jpg"
                    className="card-img-top"
                  />
              <div className="card-body">
                <Link to="/events/university-of-rwanda-graduation-2019" className="text-link">
                  University of Rwanda 2019 Graduation!
                </Link>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Events
